<template>
  <v-app>
    <v-main class="grey lighten-3 py-5">
      <div style="min-height: 100vh;" class="containers px-5 d-flex align-items-center">
        <div class="row d-flex justify-center align-items-center" style="height: 100%; padding: 25px 0px;">
          <div class="col-12 col-lg-5 text-center p-0 px-5">
            <img :src="require('../images/umoja.png')" class="img-fluid" data-aos="flip-up" data-aos-duration="1000" />
            <h3 class="mb-5 black--text font-weight-bold">Candidates are kindly requested to carefully read and select
              each rule.</h3>
            <div class="d-none d-xl-block">
              <v-btn large color="#FF5E21" class="white--text mb-5" :disabled="!btnStart" @click="startTest()">Start Now
                !</v-btn>
            </div>

          </div>

          <div class="col-12 col-lg-5 text-center p-0 px-5">

            <div>
              <v-item-group multiple v-model="itemi">
                <v-container>
                  <v-row>
                    <v-col v-for="(n,k) in laListe" :key="k" cols="12" class="p-0 py-1">
                      <v-item v-slot="{ isSelected, toggle }">

                        <v-card :color="tableContainsValue(k) ? 'success darken-2 white--text' : ''"
                          class="text-left elevation-13" heights="200" light @click="toggle" block>

                          {{ isSelected ? '' : '' }}
                          <div class="flex-grow-1 text-left px-5 pt-2 pb-1">
                            <h5 class="font-weight-bold">{{ n.title }}</h5>
                            <p class="px-5">{{ n.description }}</p>
                          </div>

                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>

              <div class="d-block d-xl-none my-5">
                <v-btn large color="#FF5E21" class="white--text" :disabled="!btnStart" @click="startTest()">Start Now !
                </v-btn>
              </div>

            </div>
          </div>
        </div>
      </div>
    </v-main>
    <span class="d-none">{{ ready }}</span>
  </v-app>
</template>

<script>
  export default {
    data() {
      return {
        itemi: [],
        btnStart: false,
        laListe: [{
            title: "Do not open additional tabs or windows",
            description: "Ensure that only the test tab or window is open during the duration of the test."
          },
          {
            title: "Do not use external resources",
            description: "Refrain from using any external resources such as dictionaries, translation tools, or online reference materials."
          },
          {
            title: "Do not communicate with others",
            description: "Avoid any form of communication with classmates or others during the test, including messaging, calling, or sharing answers."
          },
          {
            title: "Do not use unauthorized aids",
            description: " Do not use any unauthorized aids such as notes, textbooks, or study guides unless explicitly permitted by the teacher."
          },
          {
            title: "Do not attempt to cheat or plagiarize",
            description: "Ensure that all responses are original and represent the student's own work. Avoid any form of cheating or plagiarism."
          },
          {
            title: "Follow instructions carefully",
            description: "Read and follow all instructions provided for each section of the test, including formatting requirements and time limits."
          },
          {
            title: "Seek clarification if needed",
            description: "If there are any uncertainties or questions regarding the test instructions, seek clarification from the teacher or test administrator before proceeding."
          }

        ]
      }
    },
    computed: {
      ready() {
        return this.isGood();
      }
    },
    methods: {
      tableContainsValue(value) {
        for (let i = 0; i < this.itemi.length; i++) {
          if (this.itemi[i] == (value)) {
            return true; // Value found
          }
        }
        return false; // Value not found
      },
      isGood() {
        this.itemi = this.itemi.sort();
        var tab = [0, 1, 2, 3, 4, 5, 6];

        for (let i = 0; i < tab.length; i++) {
          if (tab[i] !== this.itemi[i]) {
            this.btnStart = false;
            return false;
          }
        }

        this.btnStart = true;

      },
      startTest() {
        this.$store.state.dejavu = true;
        this.$router.push('/startingTest');
      }
    }
  }
</script>